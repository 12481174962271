.it-performance-container {
    margin: 12px 0;
}

/* .it-performance-table-container {
    margin: 24px 0;
} */
/* .it-performance-table-Disk-Performance-container {
    margin: 12px 0;
} */
.it-performance-chart-box {
    padding: 32px;
    background: #ECEEF9;
    border-radius: 8px;
}

.it-performance-chart-box h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-transform: capitalize;
    color: #121212;
}

.it-performance-chart-y-axis-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #474747;
}

.it-performance-dount-chart-tooltip-class-div {
    max-width: 500px;
    max-height: 300px;
}


.processName {
    width: auto;
}

/* CPU Performancy */
.averageCPU {
    width: 120px;
}

.minCPU {
    width: 120px;
}

/* Memory Performancy */
.averageMemoryPercentage {
    width: 120px;
}

.averageMemoryInMb {
    width: 135px;
}

/* Disk Performancy */
.averageDiskInMb {
    width: 150px;
}

.maxDiskInMb {
    width: 155px;
}

@media screen and (max-width: 650px) {
    .processName {
        width: 160px;
        min-width: max-content;
    }
}