.appNameTitle {
  flex-grow: 3;
  text-align: left;
}

.appNameTitle h1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  margin-bottom: 0;
}
