/* .agentAvilabilityChartMainConatiner {
    border-radius: 8px;
    background-image: url("../img/ChartConatinerBackgroundWeb.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    position: relative;
}

.agentAvilabilityChartMainAbsConatiner {
    position: absolute;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.agentAvilabilityChartVectorConatiner {
    position: relative;
    z-index: 2;
    margin-top: 2.5%;
}

.agentAvilabilityChartMainConatiner .agentAvilabilityChartHeading {
    position: relative;
    z-index: 2;
    top: -13px;
}

.agentAvilabilityChartMainConatiner .agentAvilabilityChartHeading h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #121212;
    margin-left: -10px;
} */

.agentAvilableeDataContainer {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    background: #FFFFFF;
    border-radius: 8px;
}

.AgentAvailbilityDeviceContainer:nth-of-type(2) {
    padding: 0px 36px;
}

.AgentAvailbilityDeviceContainer div h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    /* line-height: 150%; */
    margin-bottom: 0;
    text-align: center;
    text-transform: capitalize;
    color: #121212;
}

.AgentAvailbilityDeviceContainer div p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #474747;
    overflow-wrap: break-word;
    min-width: 110px;
    margin-bottom: 0px;
}

@media (min-width:320px) and (max-width:500px) {
    .AgentAvailbilityDeviceContainer div {
        margin-bottom: 2rem;
    }
}

@media (min-width:320px) and (max-width:768px) {
    .agentAvilabilityChartVectorConatiner {
        margin-top: 50px;
    }
}