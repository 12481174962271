/* Tabel Headers start */
.processName {
  width: auto;
}

/* Tabel Headers end */
/* App Performance Start */
.application-performance-chart-number svg {
  height: 100px;
}

.application-performance-chart-number svg text {
  transform: translate(5px, 35px) !important;
}

.app-performance-modal {
  padding: 1rem 0;
}

.app-performance-modal .customTab ul.nav-tabs {
  position: relative;
  z-index: 2;
  padding: 13px !important;
}

.app-performance-modal .customTab ul.nav-tabs::before {
  content: "";
  display: none;
  width: 92%;
  z-index: 1;
  height: 0.5rem;
  background-color: #4650be;
  position: absolute;
  top: 7.2rem;
  border-radius: 1rem;
}

.SummaryCardCSS {
  width: 33.33%;
  min-width: min-content;
}

.SummeryCardHeader h2 {
  width: 100%;
  color: #4650be;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /* margin-bottom: 100px; */
}

.headersStyle h1 {
  font-size: 1.25rem !important;
}

/* @media screen and (max-width: 1070px) {
  .app-performance-chart-details {
    display: flex;
    flex-direction: column;
  }
} */
@media screen and (max-width: 1000px) {
  .app-performance-chart-details {
    flex-direction: unset;
  }
}

@media screen and (max-width: 1100px) {
  .processName {
    width: 160px;
    min-width: max-content;
  }
}

/* Old condtion for fixed width */
/* @media screen and (max-width: 1115px) {
   .SummaryCardCSS {
    width: 350px;
  } 
} */
/* New condition associated with min-width: min-content */
@media screen and (max-width: 480px) {
  .SummaryCardCSS {
    width: 350px;
  }
}

.state-info {
  font-size: 13.5px;
  margin-left: 10px;
}

.state-info div:nth-child(1) {
  margin-right: 5px;
}

.low {
  color: #F04438;
}