.table-container {
  display: grid;
  overflow-x: auto;
  /* height: 55vh; */
  /* overflow: hidden; */
}



.table-search-row {
  display: flex;
  width: 100%;
  /* display: table; */
  /* overflow-x: auto; */
  justify-content: space-between;
  padding: 10px;
  background: #4453c5;
  color: white;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  /* align-items: center; */
  /* margin-bottom: 1rem; */
  /* align-items: flex-end; */
  /* height: 65px; */
}

.tabel-search-left-side {
  display: flex;
}

.table-search {
  display: flex;
  align-items: center;
  margin-right: auto;
  width: 320px;
  left: 0px;
  top: 0px;
  background-color: white;
  border-radius: 6px;
  padding-left: 13px;
  margin: 0 10px;
  /* display: flex; */
  /* justify-content: flex-end; */
  /* align-items: flex-end; */
  /* height: 100%; */
  /* padding: 6px 12px 6px 12px; */
}

.table-search input {
  flex: 1;
  height: 40px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
  color: #B9B9B9;
}
.table-search button{
  background-color: transparent;
}

.SearchIconstyle {
  height: 14px;
  width: 14px;
  left: 1px;
  top: 1px;
  border-radius: 0px;
  color: #b9b9b9;
}

/* .buttons-container {
  display: flex;
  align-items: center;
} */
.table-show-entries {
  display: flex;
  align-items: center;
  /* margin-right: 16px; */
}

.table-show-entries label {
  margin-right: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
  color: #FFFFFF;
}

.custom-select {
  display: flex;
  align-items: center;
  height: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #4453C5 !important;
  width: 70px;
  border-radius: 8px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  background: #eceef9 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}

.download-btns-container {
  display: flex;
  justify-content: space-between;
  color: #4453c5;
  align-items: center;
}

.table-show-entries-mob,
.table-show-entries-txt {
  display: none;
}

/* PDF */
.download-btn-csv button,
.download-btn-pdf button {
  background: 0;
  outline: 0;
  border: 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #4453C5;
  background: #ECEEF9;
  transition: all 0.3s;
  height: 40px;
  width: 93px;
  border-radius: 8px;
  padding: 12px 15px 15px 15px;
  margin: 0 5px;
}

.download-btn-csv button:hover, .download-btn-pdf button:hover {
  background: #E1E5FF;
}

.excel-img, .pdf-img {
  width: 20px;
  margin-right: 2px;
}

.excel-img {
  margin-top: -2px;
}

.pdf-img {
  margin-top: -5px;
}

.icon-file-pdf {
  color: red;
}

/* Exel */
/* {
  display: flex;
  background: #eceef9;
  margin-left: 16px;
  height: 40px;
  width: 93px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 12px 16px 12px 18px;
  display: inline-grid;
  align-items: center;
  text-align: center;
} */
.download-btn-csv a {
  padding: 0;
  color: #4453c5;
}

.icon-file-excel {
  color: green;
}

/* I-pad size */
@media screen and (max-width: 768px) {
  .table-search {
    margin-right: 0;
    justify-content: center;

  }

  /* User Drop Down*/
  /*.user-filter-dropdown-menu {
    height: 200px;
  } */
  /* Table Search  */
  .table-search-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem 0;
  }

  .table-show-entries {
    justify-content: center;
  }

  .login-error-span {
    top: 38.5%;
    left: 3rem;
  }
}

/* Mobile size */
@media screen and (max-width: 500px) {
  .table-container {
    display: block;
    overflow-x: hidden;
  }

  .table-search-row {
    overflow-x: hidden;
  }

  .table-search {
    height: 40px;
    width: 100%;
    /* left: 0px;
    top: 38px; */
    border-radius: 6px;
    padding: 6px 12px 6px 12px;
    margin: 0;
    margin-top: 1.4rem;

  }

  .table-show-entries {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: min-content;
  }

  .table-show-entries-txt {
    display: flex;
    width: 100%;
  }

  .table-show-entries-mob {
    display: flex;
  }

  .table-show-entries-mob label {
    margin-right: 5px;
    font-size: 16px;
  }

  .download-btns-container {
    width: 100%;
    align-items: center;
  }

  .download-btn-pdf {
    margin: 0px 0px 0px 2px;
  }

  /* .download-btn-csv {
    margin: 0px 0px 0px 2px;
  } */
  /* .download-btns-container div {
    margin: 0;
     width: min-content;
  } */
  /* .download-btn-pdf {
    margin-right: 16px;
  } */
  .my-tabel-styling {
    overflow-x: auto;
  }
  .tabel-search-left-side {
    gap:1rem;
    place-items: center;
  }
}

/* very small screens */
@media screen and (max-width: 281px) {
  .download-btn-pdf {
    margin-right: 5px;
  }
}