.ChartMainConatiner {
    border-radius: 8px;
    background-image: url("../img/ChartContainerBg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 24px 30px;
}

.ChartVectorConatiner {
    margin-top: 1%;
}

.Chart-paragraph {
    margin-top: 8px;
}

.Chart-paragraph p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-transform: capitalize;
    color: #474747;
}

.ChartMainConatiner .ChartHeading h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-transform: capitalize;
    color: #121212;
}