.ConfigureAlertsBtnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.ConfigureAlertsBtnContainer>button {
  color: #ffffff;
  padding: 12px 16px;
  background: #0ACF97;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
}

.ConfigureAlertsBtnContainer>button:hover {
  background-color: #09b785;
}

.AlertModalNumber {
  width: 100%;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6a6a6a;
}

.AlertModalNumber::-webkit-outer-spin-button,
.AlertModalNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.Note {
  margin-top: 5px;
}

.AlertModalFotterContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.AlertModalFotterbtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

/* Configure Alerts Confirm Modal start */
.configureAlertModalDropdown {
  background-color: white;
  padding: 12px 16px;
  border: 1px solid #dcdcdc;
  color: #6a6a6a;
}

.configureAlertModalDropdown:disabled {
  background-color: #6a6a6a;
}

.ConfigureAlertsModalLableContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.ConfigureAlertsModalLable {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #474747;
}

.ExclamationIcon {
  width: 14px;
  margin-left: 5px;
  color: #6a6a6a;
}

.ExclamationIcon:hover {
  color: #2196f3;
}

.AlertsConfirmModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.AlertsConfirmModalText {
  color: #474747;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  /* max-width: 511px; */
}

/* Configure Alerts Confirm Modal end */

/* //AlertTableSwitch// */
.AlertTableSwitch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.AlertTableSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.AlertTableSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.AlertTableSlider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.AlertTableSlider {
  background-color: #2196f3;
}

input:focus+.AlertTableSlider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.AlertTableSlider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded Sliders */
.AlertTableSlider.round {
  border-radius: 34px;
}

.AlertTableSlider.round:before {
  border-radius: 50%;
}

/* /// */