.widget-box-container {
    margin-top: 16px;
}

.widget-box {
    padding: 16px;
    background: #ECEEF9;
    border-radius: 8px;
    width: 97%;
    margin: auto;
    transition: .3s ease-in;
    height: 100%;
}

.widget-box:hover {
    background: #E1E5FF;
    box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    transition: .3 ease-out;
}


.widget-box .widget-info .header-container {
    display: flex;
    flex-wrap: nowrap;
}

.widget-box .widget-info .header-container h2 {

    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 550;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #121212;
}

.widget-box .widget-info .chart-main-container {
    height: 60px;
    margin: 5px 0;
}

.widget-box .widget-info .chart-main-container>div:first-child {
    padding-left: 0 !important;
}

.widget-box .widget-info .chart-main-container h3 {
    font-weight: 600;
    font-size: 20px;
    margin: 5px 0 0 0;
    white-space: nowrap;
    text-transform: capitalize;
    color: #121212;
}

.widget-box .widget-info .chart-main-container .activeH3 {
    font-size: 16px;
}

.widget-box .widget-info .chart-Info {
    padding: 4px 8px;
    background: rgba(197, 202, 237, 0.3);
    border-radius: 4px;
    width: fit-content;
}

.widget-box .widget-info .chart-Info p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    /* margin-top: 2%; */
}

.widget-box .widget-info .chart-Info p .duartion-title {
    margin: 0 0 0 2px;
    color: #4453C5;
}

.widget-box .widget-info .chart-Info p .duration {
    color: #14A9BC;
}

.widget-box .widget-info .chart-data div:nth-child(1) {
    flex: 1;
    flex-basis: 235px
}

.widget-box .widget-info .chart-data div:nth-child(3) {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget-box .widget-info .chart-data div:nth-child(1) p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-transform: capitalize;
    color: #474747;
}

.widget-box .widget-info .chart-data .chart-data-border-line {
    width: 100%;
    margin-right: 10px;
    height: 0px;
    border: 1px dashed #C5CAED;
    border: 1px dashed #C5CAED;
}

.widget-box .widget-info h1 {
    color: #6c757d;
    margin: 0;
    font-size: 16px;
}

.widget-box .widget-info p {
    color: #98a6ad;
    font-size: 0.813rem;
    margin: 0;
}

.application-performance-chart-number svg {
    height: auto !important;
}

@media (min-width:320px) and (max-width:767px) {
    .widget-box .widget-info .chart-main-container .activeH3 {
        font-size: 15px;
    }

    .widget-box:hover {
        background: #ECEEF9;
        box-shadow: none;
        /* border-radius: 8px; */
        /* transition: .3 ease-out; */
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .widget-box .widget-info .chart-main-container .activeH3 {
        font-size: 15px;
    }
}

@media (min-width:320px) and (max-width:768px) {
    .widget-box {
        width: 98.5%;
        margin: initial;
        margin-left: auto;
        height: auto !important;
    }

    .application-performance-chart-number svg {
        height: auto !important;
    }
}

@media (min-width:1024px) and (max-width:1200px) {
    .widget-box .widget-info .chart-main-container {
        height: 75px;
    }
}