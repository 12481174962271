.application-usage-container {
    margin: 12px 0;
}

.top-app-box, .last-app-box {
    position: relative;
}

.top-app-box .box-head-info, .last-app-box .box-head-info {
    padding-bottom: 32px;
}

.top-app-box-bg {
    position: absolute;
    background-image: url("../img/max\ application\ usage.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 65%;
    width: 100%;
    top: 35%;
    left: 0;
}

.last-app-box-bg {
    position: absolute;
    background-image: url("../img/Min\ application\ usage.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 65%;
    width: 100%;
    top: 35%;
    left: 0;
}

.top-app-box {
    margin-bottom: 24px;
}


.top-app-box h3, .last-app-box h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 150%;
    text-transform: capitalize;
    color: #121212;
}

.top-app-box p, .last-app-box p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-transform: capitalize;
    color: #9C9C9C;
}

.topapp h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #4453C5;
}

.topapp h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #4453C5;
}

.lastapp h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #14A9BC;
}

.lastapp h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #14A9BC;
}

.application-usage-pie-chart-box .titlesContainer h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    padding: 4px 0;
    text-transform: capitalize;
    color: #121212;
}


.application-usage-pie-chart-Container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 1rem 0;
}

.application-usage-tooltip-class-div {
    max-width: 500px;
    max-height: 300px;
}

.application-usage-tooltip-class-div .tooltip-class-ul .tooltip-class-li {
    color: #fff !important;
}

.application-usage-tooltip-class-div p {
    color: #fff !important;
}

.processName {
    width: auto;
}

.percentageCalculation {
    width: 90px;
}

@media screen and (max-width: 768px) {
    .Chart-paragraph {
        top: 20px;
    }

    .application-usage-pie-chart-Container {
        flex-direction: column-reverse;
        margin-top: 50px;
    }
}

@media screen and (max-width: 600px) {
    .processName {
        width: 200px;
    }
}