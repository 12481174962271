/* .applicationComplianceChartMainConatiner {
    border-radius: 8px;
    background-image: url("../img/AppComplianceBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    position: relative;
}
.applicationComplianceChartMainAbsConatiner {
    position: absolute;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, #e2e5ff00 0%, #8c99ff3b 100%);
    z-index: 1;
}
.applicationComplianceChartVectorConatiner {
    position: relative;
    z-index: 2;
} */
.appComplianceDataContainer {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 32px 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    background: #FFFFFF;
    border-radius: 8px;
}

.countNo:nth-of-type(1) {
    padding-right: 18px;
}

.countNo:nth-of-type(2) {
    padding-left: 18px;
}


.countNo h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    /* line-height: 150%; */
    margin-bottom: 0;
    text-align: center;
    text-transform: capitalize;
    color: #121212;
}

.countNo p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #474747;
    overflow-wrap: break-word;
    /* max-width: 240px; */
    margin-bottom: 0px;
}

@media (min-width:320px) and (max-width:500px) {
    .countNo div {
        margin-bottom: 2rem;
    }
}

@media (min-width:320px) and (max-width:767px) {
    .countNo:nth-of-type(1) {
        padding-right: 0;
    }

    .countNo:nth-of-type(2) {
        padding-left: 0;
    }

    .countNo:nth-of-type(2) p {
        min-width: 200px;
    }
}