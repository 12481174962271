.login-bg {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0.938rem;
    background-color: #1c204f;
}

.login {
    width: 100%;
    height: 100vh;
}

.login .col-md-5 {
    background-color: #fff;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.login .col-md-7 {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.login-form {
    height: 95vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login-Head-Container {
    padding: 32px 0;
}

.login-form .sign-In-Text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #121212;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.login-form form {
    width: 80%;
}

.form-control-container {
    margin-bottom: 16px;
}

.btn-container {
    margin: 32px 0 0 0;
}

.form-control-label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 150%;
    color: #474747;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.login-form form .form-control {
    font-family: 'Poppins';
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13.77px 16px;
    color: #121212;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.login-form form .form-control:hover,
.login-form form .form-control:focus {
    border: 1px solid #4453c5;
}

.login-form form .inputIcon {
    position: relative;
}

.login-form form .inputIcon .username-Icon, .login-form form .inputIcon .Password-Icon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 44px;
    height: 100%;
    border-radius: 0.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form form .btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #FFFFFF;
    background: #3D4BB1;
    padding: 13.25px 0.938rem;
    border-radius: 6px;
    width: 100%;
}

.login-form form .btn:hover {
    background-color: #152759;
}

.login-form form .btn:focus {
    background-color: #36429E;
}

.login-form .copyright {
    text-align: center;
    color: #ffffff;
    font-size: 0.875rem;
    opacity: 0.6;
}

.login .copyright span {
    font-weight: 500;
    font-family: 'Poppins';
}

.login-about {
    background-image: url("../img/background.png");
    background-size: cover;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .3s ease-in;
}

.login-Error-Container {
    margin-bottom: 16px;
}

.login-Error {
    color: #e8635c; 
}

@media (max-width: 991px) {
    .copyright {
        margin-bottom: 10px;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    .login {
        width: 100%;
        height: auto;
    }

    .login-about {
        background-image: url("../img/tablet_background.jpg");
        height: 680px;
        margin-bottom: 50px;
    }

    .logo-container {
        margin: auto;
    }

    .login-form {
        height: 80vh;
    }
}

@media (max-width: 767px) {
    .noSpace {
        margin: 0 !important;
        padding: 0 !important;
    }

    .noMargin {
        margin: 0 !important;
    }

    .noPadding {
        padding: 0 !important;
    }

    .login {
        width: 100%;
    }

    /* .login-form, */
    .login-about {
        background-image: url("../img/mobile_background.jpg");
        height: 420px;
        background-position: top;
        margin-bottom: 90px;
    }

    .logo-container {
        margin: auto;
    }

    .login-form {
        height: 60vh;
    }

    .login-form form,
    .login-about form {
        margin-bottom: 1.875rem;
        max-width: 100%;
        /* width: 100%; */
    }

    .login-about .pic,
    .login-about h1,
    .login-about p {
        display: none;
    }
}