
.custom-control-input:not(:checked) ~ .slider {
    background-color: rgb(255, 255, 255); /* Change the background color of the toggle when it is not checked */
    border-color: gray; /* Change the background color of the toggle when it is not checked */
  }


  .custom-control-input:checked ~ .slider {
    border-color: rgb(3, 165, 3); /* Change the background color of the toggle when it is checked */
    background-color: white; /* Change the color of the toggle when it is checked */
}



.custom-control-input:not(:checked) ~ .custom-control-label::before {
    background-color: rgb(85, 85, 85); /* Change the background color of the switch circle  when it is not checked */

  }


  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: rgb(2, 173, 2); /* Change the background color of the switch circle when it is not checked */

  }