.remoteAvtionsHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.remoteAvtionsHeader>div {
  color: #474747;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.remoteAvtionsHeader>button {
 background: #0ACF97;
  color: white;
  padding: 12px 16px;
  border-radius: 6px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: all .5;
}
.remoteAvtionsHeader>button:hover{
  background-color: #09b785;
}
.ActionFormModaltext {
  width: 100%;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6a6a6a;
}

.ActionFormModaltextArea {
  width: 100%;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6a6a6a;
  min-height: 100px;
  max-height: 400px;
}

.ActionModalFooterContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ActionModalFooterBtns {
  width: fit-content;
}

.ActionModalFooterBtns button {
  margin-right: 15px;
}

.justify-content-between {
  justify-content: space-between;
}


.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.footer span {
  color: red;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}