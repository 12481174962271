.rsuite-custom-class .topFilter i {
    position: absolute;
    z-index: 10;
    inset: 0;
    right: 23px;
    top: 8px;
    margin-left: auto;
    width: 5px;
}

.icon-down-open-mini:before {
    content: '\e93a';
    font-size: 24px !important;
    font-weight: initial !important;
    line-height: 0 !important;
    width: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    color: #121212;
}

.rs-picker-toggle {
    box-shadow: 0 2px 6px 0 rgba(154, 161, 171, 0.15);
}

.rsuite-custom-class div:hover .rs-picker-default .rs-picker-toggle.rs-btn {
    background: #4650be !important;
}

.rsuite-custom-class div:hover .rs-picker-toggle-value,
.rsuite-custom-class div:hover .icon-down-open-mini:before {
    color: #fff !important;
}

.rsuite-custom-class div:hover svg {
    fill: #fff !important;
}

.icon-down-open-mini:hover .icon-down-open-mini::before {
    color: #000 !important;
}

.placement-top-end {
    position: absolute !important;
    top: 374px  !important;
    left: 321px !important;
    right: 0 !important;
    width: 650px !important;
    z-index: 10000;
}
@media (max-width: 1180) {
    .placement-top-end {
        position: absolute !important;
        top: 360px  !important;
        left: 321px !important;
        right: 0 !important;
        width: 650px !important;
        z-index: 10000;
    }
  }