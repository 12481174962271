/* Visited Sites */
.upperRow {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  /* align-items: center; */
}

.leftcolumn {
  background-color: #eceef9;
  width: 100%;
  border-radius: 8px;
  padding: 22px;
  /* height: 400px; */
  border-radius: 6px;
  margin: 0 26px 24px 0;
}



/* Right column */
.rightcolumn {
  width: 100%;
}

/******* Bar chart********/
.barHeading {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 0;
}

.visited-sites-bar-chart-box {
  width: 100%;
}

.pieHeading-container {
  width: 100%;
  text-align: left;
  margin-bottom: 2%;
}

.pieHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-transform: capitalize;
  color: #121212;
}

.visited-sites-pie-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 1rem 0;
}



.tooltip-class-div {
  max-height: 300px;
}

.apexcharts-canvas svg {
  overflow: visible;
}

.apexcharts-canvas svg foreignObject {
  overflow: visible;
}

@media screen and (max-width: 768px) {
  .visited-sites-pie-chart {
    flex-direction: column-reverse;
    margin-top: 50px;
  }
}

@media screen and (max-width: 500px) {
  .leftcolumn {
    padding: 32px 32px 32px 32px;
  }

  .visited-sites-pie-chart-box {
    display: flex;
    align-items: center;
    /* flex-direction: column-reverse; */
    flex-wrap: nowrap;
  }
}

/* very small screens */
@media screen and (max-width: 281px) {}