.page-title {
    padding: 16px;
    margin-bottom: 12px;
    background: #ECEEF9;
    border-radius: 6px;
}

.page-title h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */
    text-transform: capitalize;
    /* Gray/100 */
    color: #474747;
}

.page-title h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-transform: capitalize;
    /* 900 */
    color: #121212;
}

.page-title h3 span {
    /* Body/regular 16px */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-transform: capitalize;
    /* Gray/70 */
    color: #9C9C9C;
}

.page-title .topFilter .selectpicker-iconUsers .btn-light,
.page-title .topFilter .selectpicker-iconGroup .btn-light {
    width: 130px;
    white-space: nowrap;
}

.page-title .topFilter .selectpicker-iconUsers .btn-light:focus,
.page-title .topFilter .selectpicker-iconGroup .btn-light:focus {
    outline: none !important;
}

/* Page Header Start */
.page-header-dropdown {
    color: #000 !important;
}

.page-header-dropdown:hover {
    color: #000 !important;
    background-color: #e1e5ff !important;
}

.page-header-dropdown-light-text-active {
    color: #fff !important;
    background-color: #36429E !important;
}

/* Page Header End */
/* User Filters Drop Down Start */
.user-filter-dropdown-menu {
    overflow-y: scroll;
    max-height: 500px;
}

/* User Filter Drop Down End */
.page-title .topFilter .selectpicker-iconUsers .btn-light::before,
.page-title .topFilter .selectpicker-iconGroup .btn-light::before {
    font-family: "fontello";
    content: "\e8ce";
    margin: -0.125rem 0.313rem 0 0;
}

.page-title .topFilter .selectpicker-iconGroup .btn-light::before {
    content: "\ecfe";
}

.page-title .topFilter .btn-light {
    font-family: 'Poppins';
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #121212;
}

.page-title .topFilter .btn-light:last-child {
    margin-right: 0;
}

.page-title .topFilter .btn-light:hover,
.page-title .topFilter .btn-light:focus {
    background-color: #4650be;
    color: #ffffff;
}

.dropdown-search-input {
    padding-left: 0 !important;

    >.form-control {
        border-radius: 8px !important;
        border: 1px solid #ced4da
    }
}

.page-title .topFilter .w-auto .btn-light {
    width: auto;
}

/* rsuite-custom-class Start */
.rsuite-custom-class {
    position: relative;
}

.rs-picker,
.rs-picker-daterange,
.rs-picker-default,
.rs-picker-toggle-wrapper,
.rs-picker-has-value,
.rs-picker-cleanable {
    width: 255px;
    outline: 0 !important;
    box-shadow: unset !important;
    border: 0 !important;
}

.rsuite-custom-class .rs-picker:focus-visible {
    outline: 0;
    border: 0;
}

.rsuite-custom-class div {
    border: 0 !important;
    box-shadow: unset !important;
    outline: 0 !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
    box-shadow: unset !important;
}

.rsuite-custom-class .rs-picker .rs-picker-toggle:hover,
.rsuite-custom-class .rs-picker .rs-picker-toggle:focus {
    border: 0 !important;
}

.rsuite-custom-class .topFilter i {
    position: absolute;
    z-index: 10;
    inset: 0;
    right: 23px;
    top: 8px;
    margin-left: auto;
    width: 5px;
}

.icon-down-open-mini:before {
    content: '\e93a';
    font-size: 24px !important;
    font-weight: initial !important;
    line-height: 0 !important;
    width: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    color: #121212;
}

.rs-picker-toggle {
    box-shadow: 0 2px 6px 0 rgba(154, 161, 171, 0.15);
}

.rsuite-custom-class div:hover .rs-picker-default .rs-picker-toggle.rs-btn {
    background: #4650be !important;
}

.rsuite-custom-class div:hover .rs-picker-toggle-value,
.rsuite-custom-class div:hover .icon-down-open-mini:before {
    color: #fff !important;
}

.rsuite-custom-class div:hover svg {
    fill: #fff !important;
}

.icon-down-open-mini:hover .icon-down-open-mini::before {
    color: #000 !important;
}

.rs-picker-toggle-value,
.rs-picker-toggle-clean .rs-btn-close {
    color: #121212 !important;
}

.rs-picker-toggle-caret {
    color: #B9B9B9 !important;
}

.rs-picker-toggle-caret .rs-icon {
    fill: #6c757d !important;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 10px !important;
    margin-left: 0px;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
    padding: 0.6rem 1rem 0.5rem 1.2rem !important;
}

.rs-picker-toggle-value {
    margin-top: 0.13rem;
    font-size: 0.85rem !important;
}

.rs-btn-default input {
    display: none !important;
}

.rs-picker-toggle,
.rs-btn,
.rs-btn-default {
    border: 0 !important;
    transition: all 0.4s ease !important;
    padding: 0.5rem 1rem !important;
}

.rs-picker-toggle:hover,
.rs-btn:hover,
.rs-btn-default:hover {
    border: 0 !important;
}

.rs-picker-toggle-value {
    margin-left: 1.2rem;
    margin-right: 0.9rem;
}

.rs-picker-toggle svg {
    position: absolute !important;
    left: 13px !important;
    width: 16px !important;
}



.rs-picker-toggle {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}

.rs-anim-fade,
.rs-anim-in,
.rs-picker-daterange-menu,
.rs-picker-menu,
.placement-bottom-start {
    position: absolute !important;
    top: 170px !important;
    left: 285px !important;
    right: 0 !important;
    /* margin-left: 17.9rem !important;
    margin-right: auto !important; */
    width: 650px !important;
    z-index: 10000;
}

.rs-picker-daterange-panel {
    display: flex !important;
    flex-direction: row-reverse !important;
}

.rs-picker-toolbar {
    border: 0 !important;
    border-right: 1px solid #dddddd !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rs-picker-toolbar-ranges button {
    color: hsl(235, 0%, 0%) !important;
    width: 100%;
    text-align: left;
    padding-left: 1.5rem !important;
    border-radius: 0 !important;
    font-size: 12px !important;
    text-transform: capitalize;
}

.rs-picker-toolbar-ranges button:hover {
    background-color: #E1E5FF !important;
    text-decoration: none !important;
    color: #4453c5 !important;
}

.rs-picker-toolbar-ranges button:active {
    background-color: hsl(235, 47%, 48%) !important;
    color: #fff !important;
    text-decoration: none !important;
}

.rs-picker-toolbar-right button {
    width: 100px !important;
    background-color: #4453C5 !important;
    padding: 0.4rem !important;
    margin: 1rem 0 0 -15px;
}

.rs-picker-daterange-content {
    display: flex !important;
    flex-direction: column-reverse;
}

.rs-picker-daterange-header {
    text-align: right;
    border-top: 1px solid #ddd;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #4453C5 !important;
}

.rs-calendar-table-cell-selected-start:hover span,
.rs-calendar-table-cell-selected-end span {
    color: #fff !important;
}

.rs-picker-toggle:focus-within,
.rs-picker-toggle-active:focus-within,
.rs-btn:focus-within,
.rs-btn-default:focus-within {
    border: 0 !important;
}

.rsuite-custom-class .rs-picker-toggle-wrapper .rs-picker-toggle-active {
    box-shadow: none;
}

.rs-calendar-table-cell-in-range::before {
    background-color: #ECEEF9 !important;
}

.rs-calendar-table-cell:not(.rs-calendar-table-cell-selected):hover {
    background-color: #E1E5FF !important;
    color: #4453c5 !important;
    border: 10px;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px #4453C5 !important;
}

/* rsuite-custom-class End */
@media (max-width: 992px) {
    .page-title .topFilter {
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .app-usage-filters .page-title .topFilter {
        display: flex !important;
    }

    .app-usage-filters .page-title .topFilter {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .app-usage-filters .page-title .topFilter .col-auto {
        width: 145px !important;
    }
}

/* Application Usage Filter End */
/* Filters start */
@media screen and (max-width: 400px) {
    .page-title .topFilter .col-auto .dropdown .dropdown-toggle {
        padding: 4px !important;
    }
}

@media screen and (max-width: 500px) {
    .timeutilHeader .topFilter .col-auto {
        width: 100%;
    }

    .timeutilHeader .topFilter .w-auto .btn-light {
        width: 100% !important;
    }

    .timeutilHeader .topFilter .time-date-picker {
        width: 100%;
        margin-left: 0;
        height: 42px;
    }

    .timeutilHeader .topFilter {
        flex-wrap: nowrap;
    }

    .trendDatePicker {
        width: 170px !important;
        margin: 1rem !important;
    }

    .page-title .topFilter div .dropdown button {
        width: 100%;
    }

    .page-title .topFilter i {
        right: 2.3rem;
    }

    .page-title .topFilter .rsuite-custom-class {
        width: 92%;
    }

    .page-title .topFilter .rsuite-custom-class .topFilter {
        width: 100%;
    }

    .page-title .topFilter .rs-picker {
        width: 100%;
    }

    .timeutilHeader .topFilter .time-date-picker {
        width: 35%;
    }

    .timeutilHeader .topFilter {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

    .timeutilHeader .topFilterfirst-child {
        width: 50%;
    }

    .timeutilHeader .topFilter .time-apply-button {
        width: 95%;
        margin: 0 auto;
        margin-top: 2%;
        padding: 12px;
    }
}

.timeutilHeader {
    margin-bottom: 2%;
}

.timeutilHeader .user button {
    background-color: white;
    color: #6c7681;
}

@media screen and (max-width: 556px) {

    /* Date Filter */
    .rs-anim-fade,
    .rs-anim-in,
    .rs-picker-daterange-menu,
    .rs-picker-menu,
    .placement-bottom-start {
        top: 230px !important;
    }
}

@media screen and (max-width: 800px) {

    /* Date Filter */
    .rs-anim-fade,
    .rs-anim-in,
    .rs-picker-daterange-menu,
    .rs-picker-menu,
    .placement-bottom-start {
        top: 225px !important;
    }
}

@media screen and (max-width: 773px) {

    /* Date Filter */
    .rs-anim-fade,
    .rs-anim-in,
    .rs-picker-daterange-menu,
    .rs-picker-menu,
    .placement-bottom-start {
        top: 228px !important;
    }
}

@media screen and (max-width: 931px) {

    /* Date Filter */
    .rs-anim-fade,
    .rs-anim-in,
    .rs-picker-daterange-menu,
    .rs-picker-menu,
    .placement-bottom-start {
        width: 360px !important;
        left: 23px !important;
    }

    .rs-picker-daterange-panel {
        flex-direction: column !important;
        padding: 1rem 0;
    }

    .rs-picker-toolbar-ranges {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .rs-picker-toolbar-ranges button {
        border-radius: 1rem !important;
    }

    .rs-picker-daterange-calendar-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 590px;
        border: 0 !important;
    }

    .rs-calendar {
        border: 0 !important;
        transform: translateX(-20%);
        width: 60%;
    }
}

@media screen and (max-width: 556px) {

    /* Date Filter */
    .rs-anim-fade,
    .rs-anim-in,
    .rs-picker-daterange-menu,
    .rs-picker-menu,
    .placement-bottom-start {
        top: 230px !important;
    }
}

@media screen and (max-width: 338px) {

    /* Date Filter */
    .rs-anim-fade,
    .rs-anim-in,
    .rs-picker-daterange-menu,
    .rs-picker-menu,
    .placement-bottom-start {
        top: 257px !important;
    }
}

@media screen and (max-width: 312px) {

    /* Date Filter */
    .rs-anim-fade,
    .rs-anim-in,
    .rs-picker-daterange-menu,
    .rs-picker-menu,
    .placement-bottom-start {
        top: 300px !important;
    }
}