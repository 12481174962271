.descriptionBox {
  margin: 0 5px 0 5px;
  color: white;
  background-color: #4453c5;
  width: 18px;
  height: 19px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  /* font-size: 13px; */
  /* font-weight: bold; */
}
