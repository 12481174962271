@charset "UTF-8";

/** Fonts **/
@font-face {
  font-family: "Poppins";
  src: url("../font/Poppins-Regular.ttf");
  font-style: normal;
}
.remote-action .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input  {
  padding: 13.5px 12px !important;
}

/* @font-face {
  font-family: "Ambit";
  src: url("../font/Ambit-Regular.woff2") format("woff2"),
    url("../font/Ambit-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ambit";
  src: url("../font/Ambit-SemiBold.woff2") format("woff2"),
    url("../font/Ambit-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ambit";
  src: url("../font/Ambit-Bold.woff2") format("woff2"),
    url("../font/Ambit-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/** Font Size **/
/** Padding **/
/** Margin **/
/** Radius **/
/** shadow **/
/**/
body {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  color: #6c757d;
  background-color: #fff;
  line-height: 1.3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: #313a46;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.375rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

a img {
  border: 0 none;
}

img {
  max-width: 100%;
  height: auto;
}

a,
button {
  color: #4650be;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #6c757d;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.customTab .nav-tabs .btn.active:focus:hover,
.btn.focus,
.btn.focus:active,
.btn.active.focus,
.customTab .nav-tabs .btn.focus.active:hover,
button:focus:not(:focus-visible) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none;
}

[type="email"],
[type="number"],
[type="tel"],
[type="url"] {
  direction: inherit;
}

/******************** Login Page *******************/
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

#sidebar {
  width: 275px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: linear-gradient(180.15deg, #4453C5 -38.5%, #4355C6 -38.5%, #4356C6 -14.65%, #4258C7 -11.62%, #405DC9 -1.45%, #3A6FCE 64.28%, #19D3EB 131.31%);
  /* background: #4650be; */
  color: #ffffff;
}

#sidebar.active {
  margin-left: -275px;
}

#content {
  width: calc(100% - 260px);
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

#content.active {
  width: 100%;
}

#dismiss {
  display: none;
}

#dismiss .icon-right-small::before {
  content: "\ea58";
}

@media (max-width: 1000px) {
  #sidebar {
    margin-left: -275px;
    background: #4650be;
  }

  #sidebar.active {
    margin-left: 0;
  }

  #content {
    width: 100%;
  }

  .overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 998;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .overlay.active,
  .customTab .nav-tabs .overlay.active:hover {
    display: block;
    opacity: 1;
  }

  #dismiss {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #515bc2;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  #dismiss:hover {
    background: #ffffff;
    color: #4650be;
  }
}

/*** dropdown-menu animate **/
@media (min-width: 768px) {
  @-webkit-keyframes slideIn {
    0% {
      -webkit-transform: translateY(1rem);
      transform: translateY(1rem);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0rem);
      transform: translateY(0rem);
      opacity: 1;
    }

    0% {
      -webkit-transform: translateY(1rem);
      transform: translateY(1rem);
      opacity: 0;
    }
  }

  @keyframes slideIn {
    0% {
      -webkit-transform: translateY(1rem);
      transform: translateY(1rem);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0rem);
      transform: translateY(0rem);
      opacity: 1;
    }

    0% {
      -webkit-transform: translateY(1rem);
      transform: translateY(1rem);
      opacity: 0;
    }
  }

  .slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

/**************************************************
***************** Global Elements *****************
**************************************************/
.btn {
  border-radius: 0.375rem;
}

.bg-primary {
  background-color: #4650be !important;
}

.text-primary {
  color: #4650be !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.text-secondary {
  color: #6c757d !important;
}

.bg-success {
  background-color: #0acf97 !important;
}

.text-success {
  color: #0acf97 !important;
}

.bg-danger {
  background-color: #e7365a !important;
}

.text-danger {
  color: #e7365a !important;
}

.bg-warning {
  background-color: #ffbc00 !important;
}

.text-warning {
  color: #ffbc00 !important;
}

.bg-dark {
  background-color: #313a46 !important;
}

.text-dark {
  color: #313a46 !important;
}

.btn-list .btn {
  margin: 0.125rem;
}

.btn {
  padding: 0.5rem 0.938rem;
  font-size: 0.938rem;
  font-weight: 500;
}

.btn-primary {
  background-color: #4650be;
  border-color: #4650be;
  -webkit-box-shadow: 0 2px 6px 0 rgba(70, 80, 190, 0.35);
  box-shadow: 0 2px 6px 0 rgba(70, 80, 190, 0.35);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:focus:not(:focus-visible) {
  background-color: #3d46ae;
  border-color: #3d46ae;
  -webkit-box-shadow: 0 2px 6px 0 rgba(61, 70, 174, 0.35) !important;
  box-shadow: 0 2px 6px 0 rgba(61, 70, 174, 0.35) !important;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  -webkit-box-shadow: 0 2px 6px 0 rgba(108, 117, 125, 0.35);
  box-shadow: 0 2px 6px 0 rgba(108, 117, 125, 0.35);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:focus:not(:focus-visible) {
  background-color: #60686f;
  border-color: #60686f;
  -webkit-box-shadow: 0 2px 6px 0 rgba(96, 104, 111, 0.35) !important;
  box-shadow: 0 2px 6px 0 rgba(96, 104, 111, 0.35) !important;
}

.btn-red {
  background-color: #ff6666;
  border-color: #ff6666;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 102, 102, 0.35);
  box-shadow: 0 2px 6px 0 rgba(255, 102, 102, 0.35);
  color: #ffffff;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:focus:not(:focus-visible) {
  background-color: #ff5757;
  border-color: #ff5757;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 87, 87, 0.35) !important;
  box-shadow: 0 2px 6px 0 rgba(255, 87, 87, 0.35) !important;
  color: #ffffff;
}

.btn-success {
  background-color: #0acf97;
  border-color: #0acf97;
  -webkit-box-shadow: 0 2px 6px 0 rgba(10, 207, 151, 0.35);
  box-shadow: 0 2px 6px 0 rgba(10, 207, 151, 0.35);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:focus:not(:focus-visible) {
  background-color: #09b785;
  border-color: #09b785;
  -webkit-box-shadow: 0 2px 6px 0 rgba(9, 183, 133, 0.35) !important;
  box-shadow: 0 2px 6px 0 rgba(9, 183, 133, 0.35) !important;
}

.btn-danger {
  background-color: #e7365a;
  border-color: #e7365a;
  -webkit-box-shadow: 0 2px 6px 0 rgba(231, 54, 90, 0.35);
  box-shadow: 0 2px 6px 0 rgba(231, 54, 90, 0.35);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:focus:not(:focus-visible) {
  background-color: #e41f47;
  border-color: #e41f47;
  -webkit-box-shadow: 0 2px 6px 0 rgba(228, 31, 71, 0.35) !important;
  box-shadow: 0 2px 6px 0 rgba(228, 31, 71, 0.35) !important;
}

.btn-warning {
  background-color: #ffbc00;
  border-color: #ffbc00;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 188, 0, 0.35);
  box-shadow: 0 2px 6px 0 rgba(255, 188, 0, 0.35);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:focus:not(:focus-visible) {
  background-color: #ffc31a;
  border-color: #ffc31a;
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 195, 26, 0.35) !important;
  box-shadow: 0 2px 6px 0 rgba(255, 195, 26, 0.35) !important;
}

.btn-info {
  background-color: #39afd1;
  border-color: #39afd1;
  -webkit-box-shadow: 0 2px 6px 0 rgba(57, 175, 209, 0.35);
  box-shadow: 0 2px 6px 0 rgba(57, 175, 209, 0.35);
  color: #fff;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:focus:not(:focus-visible) {
  background-color: #2da2c3;
  border-color: #2da2c3;
  -webkit-box-shadow: 0 2px 6px 0 rgba(45, 162, 195, 0.35) !important;
  box-shadow: 0 2px 6px 0 rgba(45, 162, 195, 0.35) !important;
  color: #fff;
}

.btn-light {
  background-color: #eef2f7;
  border-color: #eef2f7;
  -webkit-box-shadow: 0 2px 6px 0 rgba(238, 242, 247, 0.35);
  box-shadow: 0 2px 6px 0 rgba(238, 242, 247, 0.35);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:focus:not(:focus-visible) {
  background-color: #dde5ef;
  border-color: #dde5ef;
  -webkit-box-shadow: 0 2px 6px 0 rgba(221, 229, 239, 0.35) !important;
  box-shadow: 0 2px 6px 0 rgba(221, 229, 239, 0.35) !important;
}

.btn-dark {
  background-color: #313a46;
  border-color: #313a46;
  -webkit-box-shadow: 0 2px 6px 0 rgba(49, 58, 70, 0.35);
  box-shadow: 0 2px 6px 0 rgba(49, 58, 70, 0.35);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:focus:not(:focus-visible) {
  background-color: #272e37;
  border-color: #272e37;
  -webkit-box-shadow: 0 2px 6px 0 rgba(39, 46, 55, 0.35) !important;
  box-shadow: 0 2px 6px 0 rgba(39, 46, 55, 0.35) !important;
}

/****/
.btn-outline-secondary {
  -webkit-box-shadow: 0 2px 6px 0 rgba(108, 117, 125, 0.2);
  box-shadow: 0 2px 6px 0 rgba(108, 117, 125, 0.2);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:focus:not(:focus-visible) {
  -webkit-box-shadow: 0 2px 6px 0 rgba(96, 104, 111, 0.2) !important;
  box-shadow: 0 2px 6px 0 rgba(96, 104, 111, 0.2) !important;
}

/***/
.btn-link {
  color: #3d46ae;
}

.btn-lg {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
}

.btn-sm {
  font-size: 0.875rem;
  padding: 0.313rem 0.625rem;
}

hr {
  opacity: 0.1;
}

.form-label {
  margin-bottom: 0.313rem;
  color: #6c757d;
  font-size: 0.875rem;
}

.form-control {
  font-size: 0.938rem;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #98a6ad;
}

.form-control::-webkit-input-placeholder {
  color: rgba(102, 102, 102, 0.6);
}

.form-control:-ms-input-placeholder {
  color: rgba(102, 102, 102, 0.6);
}

.form-control::-ms-input-placeholder {
  color: rgba(102, 102, 102, 0.6);
}

.form-control::placeholder {
  color: rgba(102, 102, 102, 0.6);
}

/****************************/

.menu-title {
  padding: 0 1.5rem 0.625rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}

/************ Menu ***********/

/************** Topbar ****************/

.top-nav {
  padding: 0 1.5rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  min-height: 70px;
  border-radius: 0.625rem 0.625rem 0 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: space-between; */
}

.topbar-search .form-control {
  border-radius: 0.375rem 0 0 0.375rem;
  padding: 0.313rem 0.938rem;
  font-size: 1rem;
  border-color: #e0e3e5;
  width: 320px;
  border-right: none;
}

.topbar-search .form-control:focus {
  border-color: #e0e3e5;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 992px) {
  .topbar-search .form-control {
    width: 126px;
  }
}

.topbar-search .btn {
  /* border: 1px solid #e0e3e5; */
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  color: #4650be;
  font-size: 0.938rem;
  border-radius: 0 0.375rem 0.375rem 0;
  padding: 0.5rem;
  border-left: none;
  position: relative;
}

.topbar-search .btn::after {
  content: " ";
  /* border-left: 1px solid #e0e3e5; */
  position: absolute;
  left: 0;
  top: 8px;
  bottom: 8px;
}

.topbar-search .btn:hover,
.topbar-search .btn:focus {
  border-color: #e0e3e5;
  color: #38409e;
}

.dropdown-menu {
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  padding: 0;
  font-size: 0.813rem;
  color: #6c757d;
  border: none;
}

.dropdown-menu li {
  border-bottom: 1px solid #f7f9fc;
}

.dropdown-menu li:last-child {
  border: none;
}

.dropdown-menu .dropdown-item {
  color: #6c757d;
  padding: 0.625rem 0.938rem;
  white-space: normal;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: #f7f9fc;
}

.topbar-menu {
  padding: 0;
}

.topbar-menu .nav-link {
  color: #98a6ad;
  font-size: 0.875rem;
  padding: 0.5rem;
}

.topbar-menu .nav-link i {
  font-size: 1.125rem;
}

.topbar-menu .dropdown-menu {
  left: inherit;
  right: 0;
}

.topbar-menu .dropdown-toggle::after {
  display: none;
}

.user-control .dropdown-toggle::after {
  margin-top: 0.5rem;
  font-size: 1rem;
  display: block;
}

.user-control .nav-link {
  background-color: #f7f9fc;
  height: 70px;
  border-left: 1px solid #f7f9fc;
  margin: 0 -1.5rem 0 1.25rem;
  border-radius: 0 0.625rem 0 0;
  padding: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 110%;
}

.user-control .nav-link:hover,
.user-control .nav-link:focus {
  background-color: #f7f9fc;
}

.user-control .userName-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 1110px;
  color: #6c757d;
}

.user-control .nav-link small {
  color: #98a6ad;
}

.user-control .user-pic {
  margin: -0.313rem 0.625rem 0 0;
  position: relative;
}

.user-control .user-pic img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.user-control .user-pic .user-status {
  position: absolute;
  right: 0;
  bottom: -3px;
  width: 10px;
  height: 10px;
  background-color: #0acf97;
  border-radius: 100%;
}

.badge-notification {
  position: absolute;
  top: 0;
  right: 3px;
  padding: 0.35em 0.55em;
}

#notification {
  max-height: 320px;
  min-width: 300px;
}

#notification h6 {
  font-size: 0.813rem;
  margin-bottom: 0.3rem;
  color: #4650be;
}

#notification i {
  font-size: 1.125rem;
}

#notification p {
  margin-bottom: 0.3rem;
}

#notification .col-2 {
  text-align: center;
}

#notification .dropdown-menu-header {
  text-align: center;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.625rem;
}

#notification .dropdown-menu-footer a {
  display: block;
  text-align: center;
  padding: 0.625rem;
}

#notification .dropdown-menu-footer a:hover {
  color: #313a46 !important;
}

@media (max-width: 768px) {
  .user-control .user-pic {
    margin-right: 0;
  }

  #notification {
    min-width: 250px;
  }

  .white-wrapper {
    margin: 0.625rem;
  }
}

#content .mCSB_dragger_bar {
  background-color: #4650be;
}

/**********************************/
.page-container {
  padding: 1.5rem 1.5rem 0;
  min-height: 83vh;
}

.customTab .tab-content {
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  background-color: #ffffff;
  border-radius: 0.5rem;
  /* padding: 1.25rem; */
  margin-bottom: 1.5rem;
}

.widgets {
  margin-bottom: 1.5rem;
}


.footerContainer {
  width: 100%;
  text-align: center;

}

.copyright2 {
  text-align: center;
  margin: 0 0 0 0 !important;
  padding: 1rem 0;
  padding: 0.625rem;
  font-size: 0.875rem;
  color: #98a6ad;
}

@media (max-width: 768px) {
  .page-container {
    min-height: 100%;
  }
}

/************** Table **************/
/* .table {
  font-size: 0.938rem;
  color: #313a46;
} */

/* .table > :not(caption) > * > * {
  padding: 0.938rem;
}

.table > :not(:first-child) {
  border-top: none;
} */

/* .table th {
  background: #4650be;
  color: #ffffff;
  font-weight: 500;
} */

/* .table th:nth-of-type(2n + 2) {
  background: #4049b5;
} */

/*********** pagination ************/
.page-link {
  color: #4453c5;
  border-color: #d0d5dd;
}

.page-link:focus {
  background-color: #fff;
  color: #4453c5;
}

.page-item.active .page-link,
.customTab .nav-tabs .page-item.active:hover .page-link {
  background-color: #4453c5;
  border-color: #d0d5dd;
}

.page-item:not(:first-child) .page-link {
  padding: 8px 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

.page-item.active .page-link,
.customTab .nav-tabs .page-item.active:hover .page-link {
  background: #eceef9;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #36429e;
  padding: 8px 14px;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  padding: 7px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-family: "Poppins";
  font-size: 16px;
  text-transform: capitalize;
  color: #474747;
}

/******************* Tabs ******************/
.customTab {
  margin: 12px 0;
}

.customTab .tab-content {
  position: relative;
  z-index: 1;
}

.customTab .nav-tabs {
  border-bottom: none;
  margin: 0 0 24px 0;
  /* padding: 0 1.5rem; */
  padding: 13px;
}

/* .customTab .nav-tabs::after {
  content: " ";
  height: 10px;
  width: 100%;
  background-color: #4650be;
  border-radius: 0.5rem 0.5rem 0 0;
  position: relative;
  z-index: -1;
  margin-top: -10px;
} */
.customTab .nav-tabs .nav-link {
  padding: 8px 16px;
  border-radius: 6px;
  margin: 0 24px;
  color: #121212;
  text-align: center;
  font-size: 16px;
}

.customTab .nav-tabs .nav-link:nth-child(1) {
  margin: 0 24px 0 0;
}

.customTab .nav-tabs .nav-link {
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  font-size: 16px;
  color: #6c757d;
  padding: 0.75rem 1.5rem;
  margin: 0;
}

.customTab .nav-tabs .nav-link:hover {
  background-color: #4453c5;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}

.customTab .nav-tabs .active {
  background-color: #4453c5;
  position: relative;
  color: #fff;
  transition: 0.3s ease-in-out;
}

@keyframes underline-animated {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.customTab .nav-tabs .active::before {
  content: "";
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #4453c5;
  height: 3px;
  /* transition: 0.2s ease-in; */
  animation: underline-animated 0.2s forwards;
}

@media (max-width: 1200px) {
  .customTab .nav-tabs .nav-link {
    font-size: 0.938rem;
    padding: 0.625rem 0.938rem;
  }
}

@media (max-width: 992px) {
  .customTab .nav-tabs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0.313rem;
  }

  .customTab .nav-tabs::after {
    display: none;
  }

  .customTab .nav-tabs .nav-link {
    font-size: 0.875rem;
    border-radius: 0.5rem;
    margin: 0.125rem;
  }
}

@media (max-width: 768px) {
  .customTab .nav-tabs .nav-link {
    font-size: 0.813rem;
    padding: 0.5rem 0.625rem;
  }
}

.customTab .tab-content > .tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.customTab .tab-content > .tab-pane.active,
.customTab .nav-tabs .tab-content > .tab-pane.active:hover {
  height: auto;
  visibility: visible;
}

/************** daterangepicker **************/
.daterangepicker {
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  padding: 0;
  font-size: 0.813rem;
  color: #6c757d;
  border: none;
}

.daterangepicker li {
  border-bottom: 1px solid #f7f9fc;
}

.daterangepicker li:last-child {
  border: none;
}

.daterangepicker .dropdown-item {
  color: #6c757d;
  padding: 0.625rem 0.938rem;
  white-space: normal;
}

.daterangepicker .dropdown-item:hover,
.daterangepicker .dropdown-item:focus {
  background-color: #f7f9fc;
}

.daterangepicker::before {
  border: none;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges .customTab .nav-tabs li.active:hover,
.customTab .nav-tabs .daterangepicker .ranges li.active:hover,
.daterangepicker td.active,
.daterangepicker .customTab .nav-tabs td.active:hover,
.customTab .nav-tabs .daterangepicker td.active:hover,
.daterangepicker td.active:hover,
.daterangepicker .customTab .nav-tabs td.active:hover,
.customTab .nav-tabs .daterangepicker td.active:hover {
  background-color: #4650be;
}

/**************bootstrap-select***************/
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select .dropdown-toggle {
  white-space: normal;
}

.bootstrap-select .bs-searchbox .form-control {
  padding: 0.125rem 0.625rem;
}

.bootstrap-select .bs-searchbox .form-control:focus {
  border-color: #e0e3e5;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bootstrap-select .bs-ok-default::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border: none;
  font-family: "fontello";
  content: "\eb89";
  color: #5c58a0;
}

.bootstrap-select .dropdown-toggle::after {
  font-family: "fontello";
  content: "\e93a";
  border: none;
}

.bootstrap-select .dropup .dropdown-toggle::after {
  font-family: "fontello";
  content: "\e93a";
  border: none;
}

.bootstrap-select .dropdown-item.active,
.bootstrap-select .customTab .nav-tabs .dropdown-item.active:hover,
.customTab .nav-tabs .bootstrap-select .dropdown-item.active:hover,
.bootstrap-select .dropdown-item:active {
  color: #fff;
  background-color: #4650be;
}

/************** Sort Table **************/
.bs-select .custom-select {
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  margin: 0 0.3rem;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}

table.dataTable {
  margin: 0;
}

table.dataTable thead .sorting::before,
table.dataTable thead .sorting_asc::before,
table.dataTable thead .sorting_desc::before,
table.dataTable thead .sorting_asc_disabled::before,
table.dataTable thead .sorting_desc_disabled::before {
  right: 1em;
  font-family: "fontello", sans-serif;
  font-weight: normal;
  content: "\e93d";
  top: 50%;
  margin-top: -1rem;
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after,
table.dataTable thead .sorting_asc_disabled::after,
table.dataTable thead .sorting_desc_disabled::after {
  right: 16px;
  font-family: "fontello", sans-serif;
  font-weight: normal;
  content: "\e93a";
  top: 50%;
  margin-top: -0.5rem;
}

/******** Modal *******/
.modal-content {
  background-color: #f7f9fc;
  /* min-height: 90vh; */
  max-height: none !important;
  border-radius: 0.5rem;
  border: none;
  position: relative;
  overflow: inherit !important;
}

.modal-content::before {
  content: " ";
  height: 12px;
  position: absolute;
  left: 20px;
  right: 20px;
  top: -12px;
  background-color: #4650be;
  border-radius: 0.5rem 0.5rem 0 0;
}

.modal-content .modal-header {
  border: none;
}

.modal-content .modal-header .modal-title {
  font-size: 1.6rem;
  font-weight: 600;
}

.modal-content .modal-header .btn-close {
  background: none;
  line-height: 100%;
  font-size: 1.5rem;
}

.modal-content .modal-header .btn-close::before {
  font-family: "fontello";
  content: "\e8d6";
}

.modal-content .modal-body {
  /* display: flex; */
  /* -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.25rem;
  margin: 0 1.25rem 1.25rem; */
}

.modal-content .modal-footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.625rem;
  background-color: #f7f9fc;
  border: none;
  margin-top: -1.25rem;
}

/******** popUpModal *******/
.popUpModal .modal-body {
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 1.5rem;
}

.popUpModal .flex-shrink-0 i {
  background-color: #f7f9fc;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  font-size: 2.2rem;
  display: block;
  text-align: center;
  padding-top: 1.25rem;
  color: #313a46;
  margin-right: 1.25rem;
}

.popUpModal h4 {
  margin: 0.313rem 0;
}

.popUpModal p {
  margin: 0;
  font-size: 0.938rem;
}

.popUpModal .popUpModalBtn {
  margin-top: 0.938rem;
  text-align: right;
}

.popUpModal .popUpModalBtn .btn {
  margin-left: 0.125rem;
}

.popUpModal .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-size: 12px;
}

/********** No Data *********/
.noData {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* min-height: 63vh; */
  text-align: center;
  padding: 1.875rem;
}

.noData i {
  margin-bottom: 1.875rem;
  display: block;
}

.noData p {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .noData {
    min-height: 100%;
  }
}

/*********************/

.chart-box {
  border: 1px solid #e0e3e5;
  border-radius: 0.5rem;
  padding: 0.938rem;
  position: relative;
  margin: 1.125rem 0 0 6px;
}

.chart-box::before {
  content: " ";
  width: 6px;
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: -6px;
  background-color: #4650be;
  border-radius: 0.5rem 0 0 0.5rem;
}

.chart-box h3 {
  color: #4650be;
  margin: 0 0 1.25rem;
  text-align: center;
}

.chart-box .gaugeChart {
  display: table;
  margin: auto;
}

.chart-box .performanceChart {
  overflow: hidden;
}

.chart-box .performanceChart > div {
  margin-top: -70px;
}

@media (max-width: 1199px) {
  .chart-box {
    margin: 0 0 1.875rem 6px;
  }
}

@media (max-width: 384px) {
  .chart-box .gaugeChart {
    margin-left: -35px;
  }
}

/******************************/

@media (max-width: 1600px) and (min-width: 1200px) {
  .performanceChart > div {
    min-width: 140%;
    margin-left: -18%;
  }
}

.pdf-btn {
  margin-top: 8px;
  float: right;
}

.pdf-btn .btn {
  font-size: 0.75rem;
  padding: 0.35rem 0.4rem;
}

@media (max-width: 992px) {
  .pdf-btn {
    margin: 0 0 10px 0;
    float: none;
  }
}

/** ِAlert **/
.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  color: #212529;
  font-size: 0.875rem;
  line-height: 1.5;
}

.alert .alert-icon {
  background: rgba(255, 255, 255, 0.2);
  padding: 0.938rem;
}

.alert .alert-message {
  padding: 0.938rem;
  width: 100%;
}

.alert-dismissible {
  padding-right: 1.875rem;
}

.alert-dismissible .btn-close {
  font-size: 0.75rem;
  padding: 1.25rem 0.938rem;
}

.alert-dismissible .btn-close:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*****************/
.chart_TimeUtilization {
  overflow: hidden;
  position: relative;
}

.chart_TimeUtilization h3 {
  color: #4650be;
  margin: 0 0 1.25rem;
  text-align: center;
  position: relative;
  z-index: 1;
}

.chart_TimeUtilization > div {
  margin-top: -30px;
  margin-bottom: -5px;
  min-width: 120%;
  margin-left: -9%;
}

.chart_TimeUtilization .vAxis {
  position: absolute;
  left: -45px;
  bottom: 45%;
  z-index: 2;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-size: 0.75rem;
}

.timeUtilization-info .card {
  margin-bottom: 0.313rem;
}

.timeUtilization-info .card-body {
  padding: 0.8rem 1rem;
}

.timeUtilization-info .col-xl-4 {
  color: #4650be;
  font-weight: 500;
}

.timeUtilization-info span {
  color: #98a6ad;
  font-size: 0.813rem;
}

/**************/


.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #4650be;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23121212'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* Golbal Space Class */
.globalSpaceBetweenContainers {
  margin: 12px 0;
}
