.more-content {
    position: absolute;
    width: 130px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    top: -60px;
    left: -130px;
}

.more-content li {
    padding: 3px;
    display: flex;
    align-items: flex-end;
    font-size: x-small;
}
.more-content li:hover{
    background-color: rgba(228, 225, 225, 0.24);
    transition: all 0.3s;
    cursor: pointer;
}

.more-content li:not(:last-child) {
    border-bottom: 1px solid #6c757d11;
}
.more-content li span {
    margin-right: 5px;
}