.noLicensesInput {
  text-align: center;
  width: 145px;
  height: 40.52px;
}

.alignItems {
  display: flex;
  align-items: center;
}

/* @media only screen and (max-width: 1000px) {
  .col-lg-6 {
    text-align: center;
  }
} */

@media only screen and (max-width: 600px) {
  .noLicensesInput {
    margin: auto;
  }
}

@media only screen and (max-width: 395px) {
  .appCap {
    padding-top: 10px;
  }
}
