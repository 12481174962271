/* Table Classes Start */
table {
  overflow-x: auto;
  /* display: block; */
  white-space: nowrap;
  /* table-layout: fixed; */
  font-size: 16px;
  /* color: #313a46; */
  margin-bottom: 0 !important;
}

.my-tabel-styling {
  overflow-x: auto;
}

.customTab .tab-content>.tab-pane.active .table-container .my-tabel-styling {
  max-height: 45vh;
}

.modal-body .table-container .my-tabel-styling {
  max-height: 55vh;
}

.table> :not(caption)>*>* {
  padding: 20px 16px 20px 16px;
}

.table> :not(:first-child) {
  border-top: none;
}

table tr:last-of-type> :not(caption) {
  border-bottom-width: 0;
}

thead {
  background-color: #e1e5ff;
}

thead th {
  cursor: pointer;
  /* width: 210px; */
  /* position: relative; */
  white-space: nowrap;
  color: #474747;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-size: 16px;
  /* width: calc(var(--variable-width) + 24px); */
}

thead th.ascending::before {
  color: hsla(235, 100%, 100%, 1);
}

thead th.descending::after {
  color: hsla(235, 100%, 100%, 1);
}

td {
  width: 210px;
  overflow-wrap: break-word;
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
  color: #474747;
}

/* BootstrapTable. */
tbody {
  background: #f7f7f7;
  color: #474747;
}

.table tfoot {
  background-color: #4650be;
  color: #ffffff;
}

.table tfoot td {
  padding: 0.313rem;
}

.table .edit-icon {
  color: #e6a900;
}

.table .delete-icon {
  color: #e7365a;
}

.table .view-icon {
  color: #4650be;
}

.table-striped>tbody>tr:nth-of-type(2n + 1)>* {
  color: #313a46;
}

.table-sm {
  font-size: 0.875rem;
}

.table-sm> :not(caption)>*>* {
  padding: 0.75rem;
}

/* 
.iChdxM {
  color: #b9b9b9 !important;
  height: 21px !important;
  width: 30px !important;
} */
/* Table Pagintaion start */
.table-pagination-row {
  display: flex;
  align-items: center;
  background: #F7F7F7;
  position: relative;
  width: 100%;
  top: 0px;
  padding: 12px 24px 16px;
  left: 0;
  margin-right: 0;
  margin-left: 0;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.table-pagination-entries {
  display: flex;
  align-items: flex-start;
  margin-left: -21px;
}

.table-pagination-numbers {
  display: flex;
  justify-content: flex-end;
  margin-right: -6px;
}

@media screen and (max-width: 769px) {
  .customTab .tab-content>.tab-pane.active .table-container .my-tabel-styling {
    max-height: 35vh;
  }

  .modal-body .table-container .my-tabel-styling {
    max-height: 45vh;
  }

  .admin-management-table table th {
    width: 130px !important;
  }

}

@media screen and (max-width: 991px) {
  .table-pagination-numbers {
    justify-content: center;
    margin-right: 0px;
  }

  .table-pagination-entries {
    justify-content: center;
    margin-left: 0px;
  }
}

/************** Sort Table **************/
/* .bs-select {
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  margin: 0 0.3rem;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
} */
table.dataTable {
  margin: 0;
}

table.dataTable thead .sorting::before,
table.dataTable thead .sorting_asc::before,
table.dataTable thead .sorting_desc::before,
table.dataTable thead .sorting_asc_disabled::before,
table.dataTable thead .sorting_desc_disabled::before {
  right: 1em;
  font-family: "fontello", sans-serif;
  font-weight: normal;
  content: "\e93d";
  top: 50%;
  margin-top: -1rem;
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after,
table.dataTable thead .sorting_asc_disabled::after,
table.dataTable thead .sorting_desc_disabled::after {
  right: 16px;
  font-family: "fontello", sans-serif;
  font-weight: normal;
  content: "\e93a";
  top: 50%;
  margin-top: -0.5rem;
}

/******************* ScrollBar *************/
/* .my-tabel-styling::-webkit-scrollbar   */
.my-tabel-styling::-webkit-scrollbar {
  border-radius: 0;
  background-color: #d5d5d5;
  height: 10px;
}

.my-tabel-styling::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/******************* Tabs ******************/
/* 
.customTab .nav-tabs {
  padding: 13px !important;
}
.modal-table-tabs {
  margin: 15px 0;
}
.modal-table-tabs .tab-content {
  position: relative;
  z-index: 1;
}
.modal-table-tabs .nav-tabs {
  border-bottom: none;
  padding: 0 1.5rem;
}
.modal-table-tabs .nav-tabs::after {
  content: " ";
  height: 10px;
  width: 100%;
  background-color: #4650be;
  border-radius: 0.5rem 0.5rem 0 0;
  position: relative;
  z-index: -1;
  margin-top: -10px;
}
.modal-table-tabs .nav-tabs .nav-link {
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  font-size: 1rem;
  color: #6c757d;
  padding: 0.75rem 1.5rem;
  margin: 0;
}
.modal-table-tabs .nav-tabs .nav-link:hover {
  color: #4650be;
}
.modal-table-tabs .nav-tabs .active,
.modal-table-tabs .nav-tabs .active:hover {
  background-color: #4650be;
  color: #ffffff;
}
@media (max-width: 1200px) {
  .modal-table-tabs .nav-tabs .nav-link {
    font-size: 0.938rem;
    padding: 0.625rem 0.938rem;
  }
}
@media (max-width: 992px) {
  .modal-table-tabs .nav-tabs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0.313rem;
  }
  .modal-table-tabs .nav-tabs::after {
    display: none;
  }
  .modal-table-tabs .nav-tabs .nav-link {
    font-size: 0.875rem;
    border-radius: 0.5rem;
    margin: 0.125rem;
  }
}
@media (max-width: 768px) {
  .modal-table-tabs .nav-tabs .nav-link {
    font-size: 0.813rem;
    padding: 0.5rem 0.625rem;
  }
}
.modal-table-tabs .tab-content>.tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.modal-table-tabs .tab-content>.tab-pane.active,
.modal-table-tabs .nav-tabs .tab-content>.tab-pane.active:hover {
  height: auto;
  visibility: visible;
} */