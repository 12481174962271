/* Custom Classes Start */
/* Global Classes Start */
* {
  outline: none !important;
}

.pointer-none {
  pointer-events: none;
}

.mouse-pointer {
  cursor: pointer;
}

.login-error-input {
  border: 1px solid #d92d20 !important;
  border-radius: 0.5rem;
}

.login-error-span {
  color: #d92d20;
  position: absolute;
  top: 46%;
}

.error-span {
  color: #e8635c;
  margin-left: 0.3rem;
}

.Span-No-Data {
  margin: 10px;
  font-size: 15px;
}

.error-input {
  border: 1px solid #e8635c;
  margin-bottom: 0.5rem;
}

/* DropDown Search Start */
.dropdown-search-input input {
  width: 90%;
  margin: 0.5rem auto;
}

/* DropDown Search End */
/* Custom Scroll-bar Start */
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: #f7f7f7;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #b3b1b1;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #979797;
}

/* Custom Scroll-bar End */
/* Modals Start */
.modal-header .modal-title {
  font-size: 1.6rem;
  font-weight: 600;
  width: 760px;
  overflow-wrap: break-word;
}

.modal-title h4 {
  width: 100px !important;
}

/* Modals End */
/* Google Charts Edits Start */
.google-chart-chart-box {
  height: 480px;
  display: flex;
  align-items: center;
}

/* AgentAvailability Start */
.AgentAvailability-chart-number {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AgentAvailability-chart-number svg {
  height: 200px;
}

.AgentAvailability-chart-number svg text {
  transform: translate(10px, 80px) !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
}

/* AgentAvailability End */

/* Page Header Start */
.page-header-dropdown {
  color: #000 !important;
}

.page-header-dropdown:hover {
  color: #fff !important;
  background-color: #0d6efd !important;
}

.page-header-dropdown-light-text-active {
  color: #fff !important;
  background-color: #0d6efd !important;
}

/* Page Header End */
/* User Filters Drop Down Start */
.user-filter-dropdown-menu {
  overflow-y: scroll;
  max-height: 500px;
}

/* User Filter Drop Down End */
/* Global Classes End */
/* Table Classaes End */
.Toastify__toast--error {
  width: 500px !important;
}

/* Components start */
/* IT Peroformance start */
.itperformance-chart span {
  display: none;
}

.performanceChart svg>g:nth-child(3) {
  display: none;
}

.performanceChart svg {
  padding-left: 1.2rem;
}

/* IT Peroformance end */
/* App Performance Start */
/* .application-performance-chart-number svg {
  height: 100px;
}
.application-performance-chart-number svg text {
  transform: translate(5px, 35px) !important;
}
.app-performance-modal {
  padding: 1rem 0;
}
.app-performance-modal .customTab ul.nav-tabs {
  position: relative;
  z-index: 2;
}
.app-performance-modal .customTab ul.nav-tabs::before {
  content: "";
  display: none;
  width: 92%;
  z-index: 1;
  height: 0.5rem;
  background-color: #4650be;
  position: absolute;
  top: 7.2rem;
  border-radius: 1rem;
} */
/* App Performance End */
/* IT Performance Classes Start */
.trend-filter-apply-button {
  width: 100px;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4049b5;
  color: #fff;
  cursor: pointer;
}

/* It Performance Date Picker start */
.trendmodalheader .topFilter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trendDatePicker {
  padding: 0.5rem 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trend-filter-apply-button {
  width: 100px;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4049b5;
  color: #fff;
  cursor: pointer;
}

/* It Performance Date Picker end */
/* IT Performance Classes End */
/* App Compliance Chart Number Start */
.application-compliance-chart-number {
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.application-compliance-chart-number svg {
  height: 290px;
}

.application-compliance-chart-number svg text {
  transform: translate(5px, 120px) !important;
}

/* App Compliance Chart Number End */
/* Time Tracker Start */
.time-tracker-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 1rem;
  margin: 1rem 0;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 0.1px rgba(0, 0, 0, 0.02),
    0px 0px 0.3px rgba(0, 0, 0, 0.028), 0px 0px 0.6px rgba(0, 0, 0, 0.035),
    0px 0px 1.1px rgba(0, 0, 0, 0.042), 0px 0px 2.1px rgba(0, 0, 0, 0.05),
    0px 0px 5px rgba(0, 0, 0, 0.07);
  /* overflow: hidden; */
}

.timeTrackerDropDown .dropdown button {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  width: max-content !important;
}

.time-tracker-description {
  height: 100%;
  width: 90%;
  background: transparent;
  outline: 0;
  border: 0;
  font-size: 1rem;
  margin-left: 1rem;
  padding-left: 1rem;
}

.time-tracker-time-container {
  padding: 0 0.7rem;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.timeutilWrapper div div div:first-child {
  margin: auto !important;
}

@media screen and (max-width: 1169px) {
  .installed-apps-dropdown .dropdown {
    display: flex;
  }
}

.installed-apps-dropdown .user-filter-dropdown-menu {
  width: 180px;
}

.time-tracker-btn {
  background: #4650be;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  border-radius: 0.3rem;
  font-size: 1rem;
}

.time-tracker-duration-container {
  width: 100px;
  padding: 0 1rem;
}

.time-tracker-listItem-time-container {
  width: 350px;
}

@media screen and (max-width: 693px) {
  .time-tracker-card {
    overflow: scroll;
    height: 100px;
  }

  .time-tracker-description {
    width: 200px;
  }

  /* .time-tracker-description {
    width: 50%;
    padding: 0;
    margin-left: 0.3rem;
  }
  .time-tracker-time-container {
    padding: 0 0.3rem;
  }
  .time-tracker-btn {
    margin-left: 0.5rem;
  }
  .timeTrackerDropDown .dropdown button {
    width: 160px !important;
    padding: 0.3rem !important;
  } */
}

/* Time Tracker End */
/* Application License Drop Down Start */
.dropdown-toggle {
  white-space: unset !important;
}

/* Application License Drop Down End */
/* DropDown whitespace start */
.dropdown-toggle {
  white-space: wrap !important;
}

.width-max-content .allowedapps .dropdown-toggle {
  width: max-content;
}

/* DropDown whitespace End */
/* Application Usage Filter Start */
/* Filters end */
/* WebAvailability Trend Date Filter Start */
.trend-date-picker {
  height: 50px;
  margin-left: auto;
}

.trend-date-picker-bg-color {
  background-color: #eee !important;
}

.trend-date-picker-bg-color:hover {
  background-color: hsl(235, 47%, 48%) !important;
  color: #fff;
}

/* WebAvailability Trend Date Filter End */
/* Allowed Applications Start */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #4453C5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: #4453C5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #fff;
  border: 1px solid #4453C5;

}

input:focus+.slider {
  box-shadow: 0 0 1px #fff;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* .test-tooltip .apexcharts-tooltip {
  background: #f3f3f3 !important;
  color: orange !important;
} */
.tooltip-class-div {
  max-width: 300px;
}

.tooltip-class-ul {
  text-align: left;
}

.tooltip-class-li {
  text-align: left;
  text-decoration: dotted;
}

.test-tooltip .apexcharts-theme-dark {
  color: #f00 !important;
}

.expiredRow td {
  color: #d92d20 !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
  background: #fff !important;
  color: #121212 !important;
}

.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
  color: #fff !important;
}

/* .apexcharts-tooltip.apexcharts-theme-dark {
  background: #fff !important;
  color: #121212 !important;
}

.apexcharts-yaxistooltip.apexcharts-theme-dark {
  background: #fff !important;
  color: #121212 !important;
}

.apexcharts-xaxistooltip.apexcharts-theme-darkapexcharts-xaxistooltip {
  color: #fff !important;
} */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Allowed Applications End */
/* Time Utilization Start */
.time-date-picker {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 0.375rem;
  color: #6c7681;
  transition: all 0.15s ease-in-out;
  margin-right: 1rem;
  margin-left: 1rem;
  position: relative;
  box-shadow: 0 2px 6px 0 rgba(154, 161, 171, 0.15);
}

.time-date-picker:hover {
  background-color: hsl(235, 47%, 48%);
  color: #fff;
}

.time-date-picker .react-datepicker-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 100%;
  padding: 0 0.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.time-date-picker .react-datepicker-wrapper {
  overflow: hidden;
}

.time-date-picker .react-datepicker-wrapper .react-datepicker__input-container input {
  background: transparent;
  border: 0;
  outline: 0;
  color: #6c7681;
  font-size: 0.875rem;
  cursor: pointer;
}

.time-date-picker:hover .react-datepicker-wrapper input {
  color: #fff;
}

.time-apply-button {
  background: hsl(235, 47%, 48%);
  color: #fff;
  border-radius: 0.375rem;
  border: 0;
  outline: 0;
  font-size: 0.875rem;
  cursor: pointer;
  width: 80px;
  transition: all 0.15s ease-in-out;
  padding: 9px;
  margin-left: 3px;
}

/* Time Utilization End */
/* Routes Search => Navbar Start */
.search-routes {
  border: 1px solid #e0e3e5;
  width: 355px;
  border-radius: 0.4rem;
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 0.5rem;
  cursor: pointer;
}

.search-routes p {
  margin: 0;
}

.navbar-drop-down-search {
  border: 1px solid #e0e3e5 !important;
  margin: 0 auto 0.5rem;
  border-radius: 0.3525rem !important;
  padding-left: 0.5rem !important;
}

.invert-arrow-down {
  transform: rotate(0deg);
  transition: all 0.25s ease;
  font-size: 1.1rem !important;
}

.invert-arrow-up {
  transform: rotate(-180deg);
  transition: all 0.25s ease;
  font-size: 1.1rem !important;
}

.routes-search-container {
  position: absolute;
  z-index: 1000;
  transform: translateX(17%) translateY(-0.7rem);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 355px;
  height: 350px;
  opacity: 1;
  overflow-y: scroll;
  transition: all 0.4s ease-in;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding-top: 0.8rem;
}

.routes-search-container-hide {
  height: 0;
  opacity: 0;
  width: 0;
  transform: translateX(10%) translateY(-100rem);
}

.nav-search-btn {
  padding: 0.352rem;
  background: transparent;
  outline: 0;
  border: 0;
}

.routes-search-container p {
  color: #000;
  font-weight: bold;
  width: 100%;
  padding: 0.8rem 0.5rem;
  margin: 0;
  transition: all 0.25s ease-in;
}

.routes-search-container p:hover {
  color: #4650be;
  background: #4650be0f;
}

.active-route {
  background: #4650bedf;
  color: #fff !important;
}

.webavail .customTab {
  margin: -15px 0;
}

.webavail .customTab .tab-content {
  /* height: 500px; */
  height: fit-content;
  overflow: hidden;
}

.btn-toggle {
  border: 1px solid #4049b5;
  background-color: #4049b5;
  color: white;
  border-radius: 18px;
  padding: 8px;
}

@media screen and (max-width: 768px) {
  .navbar-drop-down-search {
    width: 80% !important;
  }
}

@media screen and (max-width: 426px) {
  .routes-search-container {
    width: 90%;
    transform: translateX(0);
  }

  .navbar-drop-down-search {
    width: 80% !important;
    display: flex !important;
  }
}

/* Routes Search => Navbar End */
/* Profile Classes Start */
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-modal-body {
  display: flex;
  flex-direction: column;
}

.profile-container {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  width: 800px;
}

.container-title {
  font-size: 1.2rem;
  color: #000;
  font-weight: 500;
}

.profile-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
}

.profile-card:last-child {
  border-bottom: 0;
}

.profile-card label {
  color: rgb(156 163 175);
  font-size: 1.1rem;
  width: 40%;
}

.profile-card p {
  font-size: 1.1rem !important;
  color: #000;
}

.profile-button {
  margin-left: auto;
  background: transparent;
  border: 1px solid transparent;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.4s ease-in;
  color: hsl(235, 47%, 48%);
}

.profile-button:hover {
  border: 1px solid hsl(235, 47%, 48%);
}

.change-password-modal-body div {
  margin: 1rem 0;
  position: relative;
}

.change-password-modal-body div i {
  position: absolute;
  right: 0;
  bottom: 0.1%;
  z-index: 1;
  color: #38409e;
  width: 44px;
  height: 44px;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  text-align: center;
  line-height: 55px;
  transition: all 0.3s;
  cursor: pointer;
}

/* Profile Classes End */
/* Reset Password Modal */
.resetPassModal form div div {
  position: relative;
}

.resetPassModal form div div i {
  position: absolute;
  right: 5px;
  bottom: 0.1%;
  z-index: 1;
  color: #38409e;
  width: 44px;
  height: 44px;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  text-align: center;
  line-height: 55px;
  transition: all 0.3s;
  cursor: pointer;
}

/* Reset Password Modal */
/* Components End */
/* Media Queries */
@media screen and (max-width: 312px) {}

@media screen and (max-width: 430px) {
  .modal-header .modal-title {
    width: 260px;
    overflow-wrap: break-word;
  }

  /* Web availability modal Start */
  .web-availability-modal {
    padding: 0 !important;
    margin: 0 !important;
  }

  .web-availability-modal .modal-dialog {
    width: 100% !important;
    margin: 0 !important;
  }

  .web-availability-modal .tab-content {
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 1rem !important;
  }

  .web-availability-modal .modal-body {
    padding: 0 !important;
    margin: 0 !important;
  }

  .modal-dialog {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }

  /* Web availability modal End */
}

@media screen and (max-width: 408px) {

  /* Applications Compliance */
  .application-compliance-chart-number {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {

  /* Applications Compliance */
  .application-compliance-chart-number {
    width: 100%;
  }

  .nav-tabs {
    flex-wrap: nowrap;
  }
}

.timeutilHeader .topFilterfirst-child {
  width: 50%;
}

.timeutilHeader .topFilter .time-apply-button {
  width: 95%;
  margin: 0 auto;
  margin-top: 2%;
  padding: 12px;
}

.timeutilHeader {
  margin-bottom: 2%;
}

.timeutilHeader .user button {
  background-color: white;
  color: #6c7681;
}

@media screen and (max-width: 556px) {

  /* Date Filter */
  .rs-anim-fade,
  .rs-anim-in,
  .rs-picker-daterange-menu,
  .rs-picker-menu,
  .placement-bottom-start {
    top: 230px !important;
    /* width: 475px !important; */
  }
}

@media screen and (max-width: 769px) {
  /* User Drop Down*/
  /*.user-filter-dropdown-menu {
    height: 200px;
  } */
  /* Table Search  */
  /* .table-search-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem 0;
  }
  .table-show-entries {
    justify-content: center;
  }
  .table-search {
    justify-content: center;
  }
  .login-error-span {
    top: 38.5%;
    left: 3rem;
  } */
}

@media screen and (max-width: 800px) {

  /* Date Filter */
  .rs-anim-fade,
  .rs-anim-in,
  .rs-picker-daterange-menu,
  .rs-picker-menu,
  .placement-bottom-start {
    top: 225px !important;
    /* width: 475px !important; */
  }
}

@media screen and (max-width: 773px) {

  /* Date Filter */
  .rs-anim-fade,
  .rs-anim-in,
  .rs-picker-daterange-menu,
  .rs-picker-menu,
  .placement-bottom-start {
    top: 228px !important;
    /* width: 475px !important; */
  }
}

@media screen and (max-width: 868px) {

  /* Table Pagination */
  .pagination {
    font-size: 0.8rem;
    margin: 0 0 0 1.4rem;
  }

  .table-pagination-row {
    flex-direction: column;
    align-items: center;
    gap: 1rem 0;
  }

  .table-pagination-entries {
    justify-content: center;
  }

  .table-pagination-numbers {
    justify-content: center;
  }
}

@media screen and (max-width: 931px) {

  /* Date Filter */
  .rs-anim-fade,
  .rs-anim-in,
  .rs-picker-daterange-menu,
  .rs-picker-menu,
  .placement-bottom-start {
    width: 360px !important;
  }

  .rs-picker-daterange-panel {
    flex-direction: column !important;
    padding: 1rem 0;
  }

  .rs-picker-toolbar-ranges {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .rs-picker-toolbar-ranges button {
    border-radius: 1rem !important;
  }

  .rs-picker-daterange-calendar-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 590px;
    border: 0 !important;
  }

  .rs-calendar {
    border: 0 !important;
    transform: translateX(-20%);
    width: 60%;
  }
}

@media screen and (max-width: 1022px) {

  /* Applications Compliance */
  .application-compliance-chart-number svg {
    height: 250px;
  }

  .application-compliance-chart-number svg text {
    transform: translate(5px, 120px) !important;
  }
}

@media screen and (max-width: 1198px) {

  /* Applications Compliance */
  .application-compliance-chart-number svg {
    height: 310px;
  }

  .application-compliance-chart-number svg text {
    transform: translate(5px, 150px) !important;
  }
}

/* @media screen and (max-width: 1436px) {
  Applications Performance
  .app-performance-chart-details {
    display: flex;
    flex-direction: column;
  }
} */