.custom-tooltip {
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    z-index: 999999;
    /* width: 304px;
    height: 133px; */
}

.custom-tooltip-main-child {
    width: 100%;
    height: 100%;
    padding: 12px;
}

.custom-tooltip-main-child {
    color: #474747;
    text-align: center;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
}

.custom-tooltip-main-child ul li {
    color: #6A6A6A !important;
    font-size: 14px !important;
    font-family: Poppins;
    line-height: 150%;
}

.tooltip-class-tabDiv .tooltip-class-tabName {
    margin-right: 5px;
}

/*----------------Custom Legend Styles------------------------*/
.legend-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.legend-container {
    margin: 8px 24px 0 24px;
    width: 100%;
    overflow: hidden;
}

.legend-container .legend-list .legend-item {
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.legend-container .legend-list .legend-item .legend-title {
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-transform: capitalize;
    color: #121212;
    /* min-width: 360px; */
    /* margin: 0 10px; */
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.legend-container .legend-list .legend-item .legend-bullet {
    display: block;
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: -2px;
}

@media screen and (max-width: 500px) {
    .legend-list {
        flex-direction: column;
    }

    /* .legend-container .legend-list .legend-item {
        justify-content: center;
    } */

    .legend-container .legend-list .legend-title {
        font-size: 12px;
        width: 85%;
    }

    .legend-container .legend-list .legend-item .legend-bullet {
        width: 10px;
        height: 10px;
        /* margin-top: 4px; */
    }
}

@media screen and (max-width:1024px) {
    .legend-list {
        justify-content: flex-start;
    }
}