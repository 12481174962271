.state-info {
    font-size: 13.5px;
    margin-left: 10px;
}

.state-info div:nth-child(1) {
    margin-right: 5px;
}

.low {
    color: #F04438;
}

.medium {
    color: #FFCC55;
}