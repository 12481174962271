.timeUtilizationContentContainer {
    padding: 32px;
    background: #ECEEF9;
    border-radius: 8px;
}

.timeUtli-Loader {
    padding: 32px;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.timeUtli-Loader .skeleton {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.timeUtli-Loader .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .css-3wui7z-MuiSkeleton-root {
    background-color: #E1e5ff !important;
} */

/* 
.timeUtli-Loader .loader .loader-element {
    color: #ECEEF9;
} */
.rs-loader .rs-loader-spin::before, .rs-loader .rs-loader-spin::after {
    width: 30px;
    height: 30px;
}

.rs-loader-spin::before {
    border: 3px solid #b9b9b9
}

.rs-loader-spin::after {
    border-color: #36429E transparent transparent;
}

.rs-loader-vertical .rs-loader-spin+.rs-loader-content {
    margin-left: 16px;
    margin-top: 16px;
}

.pie-chart-accordion-container {
    height: 457px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.Accordion-container {
    overflow-y: auto;
    /* padding: 0 10px; */
}

.accordion {
    background: #ECEEF9 !important;
}



.accordion-button {
    padding: 0 !important;
}

.accordion-button .collapse:not(.show) {
    margin-bottom: 0;
}

/* .accordion-item-container {
    margin: 10px 0;
} */
.accordion-item {
    padding: 8px 8px 8px 0 !important;
    border-radius: 10px;
    background: #ECEEF9 !important;
    border: 1px solid transparent !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 8px !important;
}

.accordion-button {
    padding: 16px !important;
    border-radius: 8px !important;
    background: #E1E5FF !important;
    color: #474747;
    border: 1px solid transparent;
    outline: none !important;
    box-shadow: none !important;
    transition: .3s ease-in-out;
}

.accordion-item:focus, .accordion-item:focus .accordion-button {
    background: #C5CAED;
}

.accordion-button:focus,
.accordion-button:active {
    color: #000;
    border-color: transparent;
}

.accordion-header:hover .accordion-button {
    color: #000;
    border: 1px solid #c5caed;
    outline: none;
    box-shadow: none;
}

.accordion-item:last-of-type {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-radius: 8px !important;
}

.accordion-body {
    padding: 0 !important;
    margin-top: 10px !important;
}

.accordion-button:not(.collapsed) {
    color: #121212;
    background: #E1E5FF !important;
    border-radius: 6px;
    padding: 16px !important;
    box-shadow: none !important;
    margin-bottom: 16px;
}

.timeUtilization-info .accordion-card {
    margin-bottom: 16px;
}

.timeUtilization-info .accordion-card .card-body {
    padding: 16px;
    background: #ECEEF9;
    border: 1px solid #C5CAED;
    border-radius: 8px;
    cursor: pointer;
    font-size: 13px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.timeUtilization-info .accordion-card .card-body .card-details {
    color: #4453C5;
    width: fit-content;
}

.timeUtilization-info .accordion-card .card-body:hover .card-details {
    color: #36429E;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    justify-content: initial !important;
}

@media (max-width:767px) {
    .pie-chart-accordion-container {
        height: 100%;
        margin-bottom: 0;
    }
}