.DiscardModalText {
  color: #474747;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  /* max-width: 511px; */
}

.DiscardModalFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.DiscardModalCanclebtn {
  padding: 8px 32px;
  border-radius: 6px;
  color: #4453c5;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
