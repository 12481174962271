/* Assign Role Modal */
.AssignRoleModalLable {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #474747;
  margin-bottom: 5px;
}

.fullWidth {
  width: 100%;
  text-align: left;
}

.AssignRoleModalSelect {
  width: 100%;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6a6a6a;
}

.AssignRoleModalSelect:disabled {
  background-color: #c9c9c9;
}

.roles-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  gap: 8px;
}

.roleIcon {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 20px;
  background-color: #ddf8fc;
}

.roleIconText {
  color: #14a9bc;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.CloseIcon {
  width: 24px;
  cursor: pointer;
  color: #14a9bc;
}

.AssignRoleModalFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.AssignRoleModalCanclebtn {
  padding: 8px 32px;
  border-radius: 6px;
  color: #4453c5;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.AssignRoleModalSubmitbtn {
  padding: 8px 33px;
  border-radius: 6px;
  background-color: #4453c5;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #ffffff;
}

.AssignRoleModalDeletebtn {
  padding: 8px 33px;
  border-radius: 6px;
  background-color: #c54444;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #ffffff;
}
