.delete-modal .modal-dialog {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
}

.delete-modal .modal-dialog-scrollable .modal-content {
    width: 75%;
    background-color: #fff;

}

.delete-modal .modal-content::before {
    position: initial;
    display: none;
}

.delete-modal .modal-header {
    padding: 37.2px 37px 5px 37px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.delete-modal .modal-content .modal-header .btn-close {
    position: initial !important;
    font-size: 24px;
    color: #9C9C9C;
    top: 0;
    right: 0;
}

.delete-modal .delete-modal-body {
    padding: 0 37px;
}

.delete-modal .Delete-icon-main-container {
    margin-bottom: 16px;
}

.delete-modal .Delete-icon-container {
    background: #c5caed82;
    padding: 6px 1px;
    border-radius: 50%;
}

.delete-modal .Delete-icon-container i {
    width: 53.92px;
    height: 60.66px;
    color: #4453C5;
    font-size: 6rem;
}

.delete-modal .delete-Modal-Description h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 16px;
}

.delete-modal .delete-Modal-Description p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #919191;
}

.delete-modal .delete-Modal-Btns-Container {
    margin: 32px;
}

.delete-modal .delete-Modal-Btns-Container .cancel-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #f7f7f7;
    padding: 8px 28px;
    background: #6A6A6A;
    border-radius: 6px;
    margin-right: 16px;
}

.delete-modal .delete-Modal-Btns-Container .delete-btn {
    padding: 8px 28px;
    background: #F04438;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}