 .select-title {
     font-size: 20px;
     color: black;
     font-weight: 600;
     margin-top: 20px;
 }

 .commands {
     color: #909090;
     font-size: 14px;
     font-weight: 400;
     margin-top: 35px;
 }

 textarea {
     resize: vertical;
     border-radius: 7px;
     border: 1px solid #DCDCDC;
     line-height: 24px;
     font-size: 16px;
 }

 .success {
     color: #34B53A;

 }

 .error {
     color: red
 }

 .text-container {
     border: 1px solid #80808024;
     border-radius: 5px;
     padding: 10px;
     line-height: 35px;
     width: 40%;
     min-height: 220px;
 }

 .state {
     font-weight: 700;
 }

 .modal-body {
     max-height: 600px;
     overflow: scroll;
 }
 .nav-item {
    cursor: pointer;
 }